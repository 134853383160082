import React, {useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import TestCard from "./TestCard";
import {getCsrfToken} from "../service/CsrfService";

function TestCards() {
    const navigate = useNavigate();
    const {id: exerciseId} = useParams();
    const [cards, setCards] = useState([])
    const [rerender, setRerender] = useState(false);
    const [markedCard, setMarkedCard] = useState([])
    const [refresh, setRefresh] = useState(true)

    const cardsToDisplay = useRef([]);
    const cardRects = useRef([]);
    // const [count, setCount] = useState(5)

    useEffect(() => {
        if (refresh) {
            setRefresh(false);
            fetch(`/api/exercise/${exerciseId}/test`)
                .then(response => response.json())
                .then(cards => {
                    if (cards.length === 0) {
                        navigate('/');
                    }
                    let displayCards = [];
                    for (let i = 0; i < cards.length; i++) {
                        displayCards.push({
                            type: "question",
                            setId: cards[i].setId,
                            id: cards[i].id,
                            title: cards[i].question,
                            display: true
                        });
                        displayCards.push({
                            type: "answer",
                            setId: cards[i].setId,
                            id: cards[i].id,
                            title: cards[i].answer,
                            display: true
                        });
                    }
                    cardsToDisplay.current = Array(displayCards.length).fill().map((_, i) => i);
                    setCards(displayCards);
                });
        }
    }, [exerciseId, refresh]);


    function hasIntersection(rect1, rect2) {
        return !(
            rect1.left + rect1.width < rect2.left ||
            rect1.left > rect2.left + rect2.width ||
            rect1.top + rect1.height < rect2.top ||
            rect1.top > rect2.top + rect2.height
        );
    }

    function isColliding(position, key, cardRects) {
        return cardRects.some((rect, i) => i !== key && hasIntersection(position, rect));
    }


    function getPosition(key) {
        console.log('key', key, rerender);
        let position;
        if (rerender) {
            position = cardRects.current[key];
            console.log('rerender position', position);
            while (isColliding(position, key, cardRects.current)) {
                console.log('colliding', key, position, cardRects.current);
                position = {
                    left: Math.floor(Math.random() * window.innerWidth * 0.8),
                    top: Math.floor(Math.random() * window.innerHeight * 0.8),
                    width: position.width,
                    height: position.height
                }
            }
            cardRects.current[key] = position;

            return {left: `${position.left}px`, top: `${position.top}px`};
        } else {
            do {
                position = {
                    left: Math.floor(Math.random() * window.innerWidth * 0.8),
                    top: Math.floor(Math.random() * window.innerHeight * 0.8),
                    width: 100,
                    height: 50

                };
                console.log('position', position);
            } while (isColliding(position, key, cardRects.current));
            cardRects.current[key] = position;
            console.log('render position', position);
            return {left: `${position.left}px`, top: `${position.top}px`};
        }
    }

    useEffect(() => {
        console.log('markedCard', markedCard);
        if (markedCard.length === 2) {
            const first = cards[markedCard[0]];
            const second = cards[markedCard[1]];
            if (first.setId === second.setId && first.id === second.id) {
                getCsrfToken()
                    .then(csrfToken =>
                        fetch(`/api/exercise/${exerciseId}/test/set/${first.setId}/card/${first.id}/success`,
                            {
                                method: 'POST',
                                headers: {'X-XSRF-Token': csrfToken}
                            }
                        ).then(_ => {
                                cardsToDisplay.current = cardsToDisplay.current.filter(i => i !== markedCard[0] && i !== markedCard[1]);
                                setMarkedCard([]);
                                if (cardsToDisplay.current.length === 0) {
                                    cardRects.current = [];
                                    setRefresh(true);
                                    setRerender(false);
                                }
                            }
                        )
                    );

            } else {
                Promise.all([
                    getCsrfToken().then(csrfToken => fetch(`/api/exercise/${exerciseId}/test/set/${first.setId}/card/${first.id}/fail`, {
                        method: 'POST',
                        headers: {'X-XSRF-Token': csrfToken}
                    })),
                    getCsrfToken().then(csrfToken => fetch(`/api/exercise/${exerciseId}/test/set/${second.setId}/card/${second.id}/fail`, {
                        method: 'POST',
                        headers: {'X-XSRF-Token': csrfToken}
                    }))]
                ).then(_ => {
                    setMarkedCard([]);
                    cardRects.current = [];
                    setRefresh(true);
                    setRerender(false);
                });
            }
        }
    }, [markedCard]);

    function onSelect(idx) {
        console.log('onSelect', idx);
        if (markedCard.includes(idx)) {
            setMarkedCard(markedCard.filter(i => i !== idx));
            return;
        }
        setMarkedCard((prev) => [...prev, idx]);
    }

    console.log('cards', cardsToDisplay.current);
    return (
        <div id='test-cards'>
            {cards.map((card, i) => {
                if (!cardsToDisplay.current.includes(i)) {
                    return null;
                }
                return <TestCard
                    key={i}
                    idx={i}
                    card={card}
                    refs={cardRects}
                    getPosition={getPosition}
                    marked={markedCard.includes(i)}
                    onSelect={onSelect}
                />;
            })}
            <div ref={nodeRef => {
                if (cardRects.current.length > 0) {
                    setRerender(true);
                }
            }}/>
        </div>
    );
}

export default TestCards;
