import React, {useEffect, useRef, useState} from 'react';
import {Box, Typography} from "@mui/material";

function CardSide({title}) {
    const cardRef = useRef();
    const contentRef = useRef();
    const [rerender, setRerender] = useState(false)
    const [variant, setVariant] = useState("h3")

    function onRef(ref) {
        console.log("onRef", ref);
        cardRef.current = ref;
    }

    useEffect(() => {
        if(cardRef.current && contentRef.current) {
            const cardWidth = cardRef.current.getBoundingClientRect().width;
            console.log("cardRef",cardRef.current, cardWidth);
            const contentWidth = contentRef.current.getBoundingClientRect().width;
            console.log("contentRef", contentRef.current, contentWidth);
            if(contentWidth > cardWidth) {
                setVariant("h4");
            } else{
                setVariant("h3");
            }
        }
    }, [title]);

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 250
            }}
            ref={cardRef}
        >
            <Typography
                align={"center"}
                ref={contentRef}
                gutterBottom
                variant={variant}
            >
                {title}
            </Typography>
        </Box>
    );
}

export default CardSide;
