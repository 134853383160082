import React, {useEffect, useState} from 'react';
import {Box, Container, IconButton, Stack, Typography} from "@mui/material";
import Paper from "@mui/material/Paper";
import {animated, useSpring} from '@react-spring/web'
import {useDrag} from "@use-gesture/react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {useNavigate} from "react-router-dom";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SettingsBackupRestoreOutlinedIcon from '@mui/icons-material/SettingsBackupRestoreOutlined';

function CheckFlashCard({
                            flashCardSetId,
                            current,
                            length,
                            idx,
                            onChange,
                            onInsert,
                            onDelete,
                            onMoveForward,
                            onMoveBackward,
                            summary
                        }) {

    const navigate = useNavigate();
    const [showDescription, setShowDescription] = useState(false);

    const width = window.innerWidth;

    const [style, api] = useSpring(() => ({x: 0, y: 0}));

    useEffect(() => {
        if (current.question !== "") {
            api.start({
                from: {x: 0, y: 0, opacity: 0},
                to: {x: 0, y: 0, opacity: 1},
                config: {duration: 500}
            })
        }
    }, [current, api, summary]);

    const bind = useDrag(({down, movement: [mx], active}) => {
        if (down && active) {
            api.start({x: mx, immediate: true})
        } else {
            if (mx > width / 5) {
                api.start({
                    x: width,
                    onResolve: () => {
                        handleKnownClick();
                        // api.start({
                        //     from: {x:0, y:0, opacity: 0},
                        //     to: {x:0, y:0, opacity: 1},
                        //     config: {duration: 500}
                        // })
                        //
                        // api.start({from: {x: 0, y: -350}, y: 0})
                    }
                });
            } else if (mx < -width / 5) {
                api.start({
                    x: -width,
                    onResolve: () => {
                        handleUnknownClick();
                        // api.start({
                        //     from: {x:0, y:0, opacity: 0},
                        //     to: {x:0, y:0, opacity: 1},
                        //     config: {duration: 500}
                        // })

                        // api.start({from: {x: 0, y: -350}, y: 0})
                    }
                });
            } else {
                api.start({
                    from: {x: mx},
                    x: 0
                });
            }
        }
    });

    if (!current.question) {
        return null;
    }

    function handleUnknownClick() {
        setShowDescription(false);
        onChange({...current, known: "UNKNOWN"}).then(() => {
            if (onMoveForward() === idx) {
                navigate(`/flash-card-sets/${flashCardSetId}`);
            }

        });
    }

    function handleKnownClick() {
        setShowDescription(false);
        onChange({...current, known: "KNOWN"}).then(() => {
            if (onMoveForward() === idx) {
                navigate(`/flash-card-sets/${flashCardSetId}`);
            }
        });
    }

    return (
        <>
            <Stack direction="row" justifyContent="space-between">
                <IconButton onClick={handleUnknownClick} sx={{color: "#ff8480"}}>
                    <HelpOutlineIcon/>
                    <Typography variant="h6">{summary.unknown}</Typography>
                </IconButton>
                <IconButton onClick={handleKnownClick} sx={{color: "#DDEE77"}}>
                    <Typography variant="h6">{summary.known}</Typography>
                    <CheckCircleOutlineIcon/>
                </IconButton>
            </Stack>
            <animated.div {...bind()} style={{...style, touchAction: "none"}}>
                <Container maxWidth="sm">
                    <Paper sx={{minHeight: 250}}>
                        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: 250}}>
                            <Typography align={"center"} gutterBottom variant="h2">
                                {current.question}
                            </Typography>
                        </Box>
                    </Paper>
                </Container>
            </animated.div>
            <Stack direction="row" justifyContent="space-between">
                <IconButton onClick={() => setShowDescription(!showDescription)}>
                    <InfoOutlinedIcon/>
                </IconButton>
                <IconButton onClick={() => onMoveBackward()}>
                    <SettingsBackupRestoreOutlinedIcon/>
                </IconButton>
            </Stack>
            <Typography
                hidden={!showDescription}
                align={"left"}
                gutterBottom
                variant="h6"
                sx={{color: "gray", margin: 0.5}}
            >
                {current.description}
            </Typography>

        </>
    )
}

export default CheckFlashCard;
