import React, {useEffect, useState} from 'react';
import Card from "@mui/material/Card";
import {Box, CardContent, IconButton, Stack, TextField} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Translation from "./Translation";

function EditFlashCard({flashCardSetId, current, setCurrent, onChange}) {
    const [showInfo, setShowInfo] = useState(false);

    useEffect(() => {
        setShowInfo(false);
    }, [current.id]);

    function handleOnChange(newValue) {
        return setCurrent(newValue);
    }


    async function handleShowInfo() {
        const b = !showInfo;
        setShowInfo(b);
        if(b && !current.translation) {
            const response = await fetch(`/api/flash-card-sets/${flashCardSetId}/cards/${current.id}/translation`);
            const data = await response.json();
            const translation = data.translation;
            handleOnChange(Object.assign({}, current, {translation}));
            setShowInfo(true);
        }
    }

    return (
        <Box>
            <Stack direction={"row"} spacing={2}>
                <Card sx={{width: '50%'}}>
                    <CardContent>
                        <TextField
                            variant={"outlined"}
                            label={"Question"}
                            sx={{width: '100%'}}
                            multiline
                            rows={7}
                            value={current.question || ''}
                            onBlur={(e) => onChange(current)}
                            onChange={(e) => handleOnChange(Object.assign({}, current, {question: e.target.value}))}
                        />
                    </CardContent>
                </Card>
                <Card sx={{width: '50%'}}>
                    <CardContent>
                        <TextField
                            variant={"outlined"}
                            label={"Answer"}
                            sx={{width: '100%'}}
                            multiline
                            rows={7}
                            value={current.answer || ''}
                            onBlur={(e) => onChange(current)}
                            onChange={(e) => handleOnChange(Object.assign({}, current, {answer: e.target.value}))}
                        />
                    </CardContent>
                </Card>
            </Stack>
            <Card sx={{width: '100%', marginTop: '1em'}}>
                <CardContent>
                    <Stack direction={"row"} spacing={2}>
                        <TextField
                            variant={"outlined"}
                            label={"Description"}
                            sx={{width: '100%'}}
                            multiline
                            rows={2}
                            value={current.description || ''}
                            onBlur={(e) => onChange(current)}
                            onChange={(e) => handleOnChange(Object.assign({}, current, {description: e.target.value}))}
                        />
                        <IconButton onClick={handleShowInfo}>
                            <InfoOutlinedIcon/>
                        </IconButton>
                    </Stack>
                    <Translation translation={current.translation} hidden={!showInfo}/>
                </CardContent>
            </Card>
        </Box>
    );
}

export default EditFlashCard;
