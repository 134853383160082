import React from 'react';
import Card from "@mui/material/Card";
import {CardContent, Typography} from "@mui/material";
import {color} from "../constant/color";

function TestCard({idx, card, refs, getPosition, onSelect, marked}) {

    const position = getPosition(idx);

    console.log('idx', idx, 'position',position);
    return (
        <Card ref={noderef => {
            if (noderef) {
                refs.current[idx] = noderef.getBoundingClientRect();
            }
        }}
              sx={{backgroundColor: marked ? color.STUDY : 'white'}}
              style={{position: 'absolute', left: position.left, top: position.top, cursor: 'pointer'}}
              onClick={() => onSelect(idx)}
        >
            <CardContent>
                <Typography variant="h6">{card.title}</Typography>
            </CardContent>
        </Card>
    );
}

export default TestCard;
