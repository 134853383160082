import {Box, Skeleton, Typography} from "@mui/material";
import React from "react";

function Translation({translation, hidden}) {
    if (hidden) {
        console.log('hide translation');
        return null;
    }
    if (!translation) {
        console.log('translation is empty');
        return <Box sx={{marginTop: '0.5em'}}>
            <Skeleton variant="rounded" width={'100%'} height={60}/>
        </Box>
    } else {
        console.log('translation is not empty');
        return (
            <Box sx={{marginTop: '0.5em'}}>
                <Typography variant={"body2"}>{translation}</Typography>
            </Box>
        );
    }
}

export default Translation;
