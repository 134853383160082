import React, {useCallback, useMemo, useState} from 'react';
import EditFlashCard from "./EditFlashCard";
import EditFlashCardsControls from "./EditFlashCardsControls";
import useCursor from "../hook/useCursor";
import {getCsrfToken} from "../service/CsrfService";


function EditFlashCards({flashCardSetId}) {
    const INITIAL_ELEMENT = useMemo(() => ({question: "", answer: "", description: "", translations: "Nothing"}), []);
    const [filter, setFilter] = useState("")

    const onUpdateFn = useCallback((newValue) => {
        console.log('updateFlashCard', newValue);
        return getCsrfToken().then((csrfToken) =>
                fetch(`/api/flash-card-sets/${flashCardSetId}/cards`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'X-XSRF-Token': csrfToken
                        },
                        body: JSON.stringify(newValue)
                    })
                    .then(response => {
                        if (response.ok) {
                            return response.json();
                        }
                        throw new Error('Failed to update card');
                    })
            // .catch(useError)
        );
    }, [flashCardSetId]);


    const onDeleteFn = useCallback((cardId) => {
        console.log('deleteFlashCard', cardId);
        return getCsrfToken()
            .then((csrfToken) =>
                    fetch(`/api/flash-card-sets/${flashCardSetId}/cards/${cardId}`,
                        {
                            method: 'DELETE',
                            headers: {
                                'X-XSRF-Token': csrfToken
                            }
                        })
                        .then(response => {
                            if (response.ok) {
                                return;
                            }
                            throw new Error('Failed to delete card');
                        })
                // .catch(useError)
            );
    }, [flashCardSetId]);

    const validateFn = valueToValidate => valueToValidate.question !== "" && valueToValidate.answer !== "";


    const fetchDataFn = useCallback(() => {
        return fetch(`/api/flash-card-sets/${flashCardSetId}/cards`)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                if (response.status === 401) {
                    return [];
                }
                throw new Error('Failed to fetch cards');
            })
            .catch(error => {
                console.error('Error fetching cards:', error);
                return [];
            });
    }, [flashCardSetId]);

    const filterFn = useCallback((card) => {
        if (filter) {
            return card.known === filter;
        }
        return true;
    }, [filter]);

    const {
        currentElement,
        setCurrentElement,
        dataLength,
        idx,
        onChange,
        insertFn,
        deleteFn,
        moveForwardFn,
        moveBackwardFn,
        summary,
        reloadFn
    } = useCursor(
        flashCardSetId,
        fetchDataFn,
        INITIAL_ELEMENT,
        onUpdateFn,
        onDeleteFn,
        validateFn,
        filterFn
    );

    function handleResetCards() {
        console.log('resetCards');
        return getCsrfToken()
            .then((csrfToken) =>
                fetch(`/api/flash-card-sets/${flashCardSetId}/cards/reset`,
                    {
                        method: 'POST',
                        headers: {
                            'X-XSRF-Token': csrfToken
                        }
                    })
                    .then(response => {
                        if (response.ok) {
                            window.location.reload();
                            return;
                        }
                        throw new Error('Failed to reset cards');
                    })
            );
    }

    return (
        <>
            <EditFlashCardsControls
                onAdd={insertFn}
                onDelete={deleteFn}
                onMoveBackward={moveBackwardFn}
                onMoveForward={moveForwardFn}
                onResetCards={handleResetCards}
                idx={idx}
                dataLength={dataLength}
                flashCardSetId={flashCardSetId}
                summary={summary}
                filter={filter}
                setFilter={setFilter}
            />
            <EditFlashCard flashCardSetId={flashCardSetId} current={currentElement} setCurrent={setCurrentElement} onChange={onChange}/>
        </>
    );
}

export default EditFlashCards;
