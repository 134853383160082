import React, {useState} from 'react';
import {animated, useSpring} from "@react-spring/web";
import {Container, IconButton, Skeleton, Stack, Typography} from "@mui/material";
import Paper from "@mui/material/Paper";
import {useDrag} from "@use-gesture/react";
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import SettingsBackupRestoreOutlinedIcon from "@mui/icons-material/SettingsBackupRestoreOutlined";
import CardSide from "./CardSide";

function StudyCard({current, summary, onChange, onMoveForward, onMoveBackward}) {
    const width = window.innerWidth;
    const [shouldFlip, setShouldFlip] = useState(false)
    const [flipped, setFlipped] = useState(false)

    const [style, api] = useSpring(() => ({
        x: 0,
        y: 0,
        transform: `rotateX(0deg)`,
        config: {mass: 10, tension: 500, friction: 100}
    }));

    const bindDrag = useDrag(({down, movement: [mx, my], active}) => {
        if (down && active) {
            let opacity = 1;
            const rotate = my / 2;

            if (rotate > 90) {
                setShouldFlip(true);
                opacity = 0;
            }
            api.start({
                x: mx,
                y: 0,
                transform: `rotateX(${rotate}deg)`,
                opacity: opacity,
                immediate: true
            });
        } else {
            if (mx > width / 5) {
                console.log("move right")
                api.start({
                    x: width,
                    onResolve: () => {
                        handleLearnedClick();
                        api.start({
                            from: {x: 0, y: 0, opacity: 0},
                            x: 0,
                            y: 0,
                            transform: `rotateX(0deg)`,
                            opacity: 1,
                        });
                    }
                });
            } else if (mx < -width / 5) {
                console.log("move left")
                api.start({
                    x: -width,
                    onResolve: () => {
                        handleUnknownClick();
                        api.start({
                            from: {x: 0, y: 0, opacity: 0},
                            x: 0,
                            y: 0,
                            transform: `rotateX(0deg)`,
                            opacity: 1,
                        });
                    }
                });
            } else {
                console.log("shouldFlip: ", shouldFlip);
                if (shouldFlip) {
                    setShouldFlip(false);
                    setFlipped(p => !p);
                }
                api.start({
                    from: {
                        x: 0,
                        y: 0,
                        transform: `rotateX(90deg)`,
                        opacity: 1
                    },
                    to: {
                        x: 0,
                        y: 0,
                        transform: `rotateX(0deg)`,
                        opacity: 1
                    }
                });
            }
        }
    }, {axis: 'lock'});


    function onClickRotate() {
        const firstAsyncResult = api.start({
            from: {transform: "rotateX(0deg)"},
            to: {transform: 'rotateX(90deg)'},
            config: {duration: 350},
            onRest: () => {
                setFlipped(p => !p)
                api.start({from: {transform: "rotateX(90deg)"}, to: {transform: 'rotateX(0deg)'}, config: {duration: 350}})
            }
        });
    }


    function handleUnknownClick() {
        setFlipped(false);
        if (current.known !== "UNKNOWN") {
            current.known = "UNKNOWN";
            onChange(current);
        }
        onMoveForward();
    }

    function handleLearnedClick() {
        setFlipped(false);
        if (current.known !== "LEARNED") {
            current.known = "LEARNED";
            onChange(current);
        }
        onMoveForward();
    }

    if (!current) {
        return (
            <Skeleton variant="rectangular" width="100%" height={250}/>
        );
    }

    return (
        <>
            <Stack direction="row" justifyContent="space-between">
                <IconButton onClick={handleUnknownClick} sx={{color: "#ff8480"}}>
                    <HelpOutlineIcon/>
                    <Typography variant="h6">{summary.unknown}</Typography>
                </IconButton>
                <IconButton onClick={handleLearnedClick} sx={{color: "#DDEE77"}}>
                    <Typography variant="h6">{summary.learned}</Typography>
                    <CheckCircleOutlineIcon/>
                </IconButton>
            </Stack>

            <animated.div {...bindDrag()} style={{...style, touchAction: "none"}}>
                <Container maxWidth="sm">
                    <Paper sx={{minHeight: 250}}>
                        <CardSide title={flipped ? current.answer : current.question}/>
                    </Paper>
                </Container>
            </animated.div>
            <Stack direction="row" justifyContent="space-between">
                <IconButton onClick={onClickRotate}>
                    <CachedOutlinedIcon/>
                </IconButton>
                <IconButton onClick={onMoveBackward}>
                    <SettingsBackupRestoreOutlinedIcon/>
                </IconButton>
            </Stack>

        </>
    );
}

export default StudyCard;
