import React from "react";
import Card from "@mui/material/Card";
import {Box, CardContent, Tooltip, Typography} from "@mui/material";
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import RepeatOneOutlinedIcon from '@mui/icons-material/RepeatOneOutlined';
import RepeatOutlinedIcon from '@mui/icons-material/RepeatOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import {color} from "../constant/color";
import {useNavigate} from "react-router-dom";

function TimelineItem({item}) {
    const navigate = useNavigate();
    const text = {
        STUDY: "Study",
        REPEAT: "Repeat",
        TEST: "Test",
        REPEAT_ALL: "Repeat all",
        REVERSE_REPEAT_ALL: "Reverse repeat all",
    }
    const icon = {
        STUDY: SchoolOutlinedIcon,
        REPEAT: RepeatOneOutlinedIcon,
        TEST: QuizOutlinedIcon,
        REPEAT_ALL: RepeatOneOutlinedIcon,
        REVERSE_REPEAT_ALL: RepeatOutlinedIcon,
    }

    console.log(item);
    let backgroundColor;
    if (item.type === "STUDY") {
        backgroundColor = color.STUDY;
    }

    if (item.type === "REPEAT") {
        const dueDate = Date.parse(item.dueDate);
        const now = Date.now();
        const differenceInMilliseconds = now - dueDate;
        const hoursDifference = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
        if (hoursDifference <= 0) {
            backgroundColor = color.GOOD_DUE_DATE;
        }
        if (hoursDifference > 0) {
            backgroundColor = color.GOOD_DUE_DATE;
        }
        if (hoursDifference > 12) {
            backgroundColor = color.BAD_DUE_DATE;
        }
        if (hoursDifference > 24) {
            backgroundColor = color.WORST_DUE_DATE;
        }
    }
    if (item.type === "TEST") {
        backgroundColor = color.STUDY;
    }
    if (item.type === "REPEAT_ALL") {
        backgroundColor = color.STUDY;
    }
    if (item.type === "REVERSE_REPEAT_ALL") {
        backgroundColor = color.STUDY;
    }

    function onClick(e) {
        if (item.type === "STUDY") {
            navigate(`/study/${item.id}`);
        } else if (item.type === "REPEAT") {
            navigate(`/repeat/${item.id}`);
        } else if (item.type === "TEST") {
            navigate(`/test/${item.id}`);
        } else if (item.type === "REPEAT_ALL") {
            navigate(`/repeat-all/${item.id}`);
        } else if (item.type === "REVERSE_REPEAT_ALL") {
            navigate(`/reverse-repeat-all/${item.id}`);
        }
    }

    return (
        <Tooltip title={text[item.type]}>
            <Card variant={'outlined'}
                  onClick={onClick}
                  sx={{
                      minHeight: 30,
                      backgroundColor: backgroundColor,
                      cursor: "pointer"

                  }}
            >
                <CardContent>
                    {React.createElement(icon[item.type])}
                    {item.count}
                    {item.dueDate && <Box><br/><Typography variant="body2" color="text.secondary">Due
                        date: {new Date(item.dueDate).toLocaleDateString()}</Typography></Box>}
                </CardContent>
            </Card>
        </Tooltip>
    );
}

export default TimelineItem;
