import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import StudyCard from "./StudyCard";
import {getCsrfToken} from "../service/CsrfService";

function ReverseRepeatAllCards() {
    const navigate = useNavigate();
    const {id: exerciseId} = useParams();
    const [history, setHistory] = useState([])
    const [studyCards, setStudyCards] = useState([])
    const [idx, setIdx] = useState(0)


    useEffect(() => {
        fetch(`/api/exercise/${exerciseId}/reverse_repeat_all`)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Failed to fetch exercise cards');
            })
            .then(data => {
                setStudyCards(data);
            })
    }, [exerciseId]);

    function handleOnChange(newValue) {
        if (newValue.known === "LEARNED" && newValue.setId && newValue.id) {
            getCsrfToken()
                .then(csrfToken =>
                    fetch(`/api/exercise/${exerciseId}/test/set/${newValue.setId}/card/${newValue.id}/success`,
                        {
                            method: 'POST',
                            headers: {'X-XSRF-Token': csrfToken}
                        }
                    )
                );
        }
        setStudyCards(studyCards.map((element, index) => index === idx ? newValue : element));
    }

    function handleOnMoveForward() {
        if (studyCards[idx].known === "UNKNOWN") {
            console.log("Skipping card", exerciseId, studyCards[idx].setId, studyCards[idx].id);
            getCsrfToken().then(csrfToken =>
                fetch(`/api/exercise/${exerciseId}/test/set/${studyCards[idx].setId}/card/${studyCards[idx].id}/skip`,
                    {
                        method: 'POST',
                        headers: {'X-XSRF-Token': csrfToken}
                    }
                )
            );
        }
        let newIdx = null
        for (let i = idx + 1; i < studyCards.length; i++) {
            if (studyCards[i].known === "UNKNOWN") {
                newIdx = i
                break
            }
        }
        if (newIdx === null) {
            for (let i = 0; i < idx; i++) {
                if (studyCards[i].known === "UNKNOWN") {
                    newIdx = i
                    break
                }
            }
        }
        if (newIdx === null) {
            navigate('/')
        }
        setHistory([...history, idx])
        setIdx(newIdx)
    }

    function handleOnMoveBackward() {
        if (history.length > 0) {
            const newIdx = history.pop()
            setIdx(newIdx)
        }
    }

    function calcSummary() {
        return studyCards.reduce((acc, element) => {
            if (element.known === "UNDEFINED") {
                return {...acc, undefined: acc.undefined + 1};
            }
            if (element.known === "UNKNOWN") {
                return {...acc, unknown: acc.unknown + 1};
            }
            if (element.known === "KNOWN") {
                return {...acc, known: acc.known + 1};
            }
            if (element.known === "LEARNED") {
                return {...acc, learned: acc.learned + 1};
            }
            return acc;
        }, {unknown: 0, known: 0, learned: 0, undefined: 0});
    }

    return (
        <div>
            <StudyCard current={studyCards[idx]} summary={calcSummary()} onChange={handleOnChange}
                       onMoveForward={handleOnMoveForward} onMoveBackward={handleOnMoveBackward}/>
        </div>
    );
}

export default ReverseRepeatAllCards;
