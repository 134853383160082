import React, {useContext, useEffect, useState} from 'react';
import {Stack, TextField} from "@mui/material";
import {ErrorStateContext} from "../context/ErrorContext";
import {getCsrfToken} from "../service/CsrfService";
import CardSetProgress from "./CardSetProgress";
import {useNavigate} from "react-router-dom";

function FlashCardSetHeader({id, disabled = false}) {
    console.log('FlashCardSetHeader.js', id);
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [progress, setProgress] = useState({count: 0, unknown: 0, known: 0, learned: 0, undefined: 0});
    const {dispatch: errorDispatch} = useContext(ErrorStateContext);

    const [timeoutId, setTimeoutId] = useState(null);
    const [toUpdate, setToUpdate] = useState(false);

    useEffect(() => {
        if (id) {
            fetch(`/api/flash-card-sets/${id}`)
                .then(response => {
                    if (response.ok) {
                        return response.json()
                    }
                    throw new Error('Failed to fetch card set');
                })
                .then(data => {
                    console.log(data);
                    setName(data.name);
                    setProgress({
                        count: data.count,
                        unknown: data.unknown,
                        known: data.known,
                        learned: data.learned,
                        undefined: data.undefined
                    });
                })
                .catch(error => {
                    console.error('Error fetching card set:', error);
                });
        }
    }, [id]);


    useEffect(() => {
        if (toUpdate) {
            setToUpdate(false);
            getCsrfToken()
                .then(csrfToken =>
                    fetch('/api/flash-card-sets', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'X-XSRF-Token': csrfToken
                        },
                        body: JSON.stringify(id ? {id, name} : {name})
                    }))
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error('Failed to update card set');
                })
                .then(data => {
                    if (data.id && data.id !== id) {
                        navigate(`/flash-card-sets/${data.id}`);
                    }
                })
        }
    }, [toUpdate, id, name, errorDispatch]);


    function handleChangeName(event) {
        console.log("handleChangeName", event.target.value);
        const newName = event.target.value;
        setName(newName);
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        setTimeoutId(setTimeout(() => {
            setToUpdate(true);
        }, 1000));
    }

    return (
        <Stack direction="column" spacing={1} marginBottom="1em">
            <TextField
                disabled={disabled}
                id="flash-card-set-name"
                label="Name"
                variant="outlined"
                sx={{
                    width: '100%'
                }}
                value={name}
                onChange={handleChangeName}
            />
            <CardSetProgress progress={progress}/>
        </Stack>
    );
}

export default FlashCardSetHeader;
