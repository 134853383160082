import React from 'react';
import {Container, Skeleton} from "@mui/material";
import Header from "./Header";
import {useParams} from "react-router-dom";
import FlashCardSetHeader from "./FlashCardSetHeader";
import EditFlashCards from "./EditFlashCards";

function EditFlashCardSet() {
    const {id} = useParams();

    if (!id) {
        return (
            <Container maxWidth="xlg">
                <Header/>
                <Skeleton variant="rectangular"/>
            </Container>
        );
    }
    return (
        <Container maxWidth="md">
            <Header/>
            <FlashCardSetHeader id={id}/>
            <EditFlashCards flashCardSetId={id}/>
        </Container>
    );
}

export default EditFlashCardSet;
