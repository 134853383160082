import React, {useEffect, useState} from "react";
import {Skeleton, Stack} from "@mui/material";
import TimelineItem from "./TimelineItem";

function Timeline() {
    const [timeline, setTimeline] = useState([])

    useEffect(() => {
        fetch("/api/timeline")
            .then((response) => response.json())
            .then((data) => {
                setTimeline(data);
            })
            .catch((error) => {
                console.error('Error fetching timeline data:', error);
            });
    }, []);


    if (timeline.length > 0) {
        return (
            <Stack direction="row">
                {timeline.map((item) => (
                    <TimelineItem key={item.type} item={item}/>
                ))}
            </Stack>
        );
    }
    return (
        <Skeleton variant="rectangular" height={100}/>
    );
}

export default Timeline;
