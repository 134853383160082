import {Box, Chip, FormControl, IconButton, InputLabel, MenuItem, Select, Stack} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ChecklistIcon from '@mui/icons-material/Checklist';
import RestoreIcon from '@mui/icons-material/Restore';
import React from "react";
import {useNavigate} from "react-router-dom";
import PlayForWorkIcon from "@mui/icons-material/PlayForWork";
import Grid from "@mui/material/Grid2";
import FastRewindOutlinedIcon from '@mui/icons-material/FastRewindOutlined';
import FastForwardOutlinedIcon from '@mui/icons-material/FastForwardOutlined';

function EditFlashCardsControls(
    {
        onAdd,
        onDelete,
        onMoveBackward,
        onMoveForward,
        onResetCards,
        idx,
        dataLength,
        flashCardSetId,
        summary,
        filter,
        setFilter
    }
) {
    const navigate = useNavigate();

    function handleFilterChange(event) {
        setFilter(event.target.value);
    }

    return (
        <Grid container spacing={1} marginBottom="1em">
            <Grid item xs={'auto'}>
                <IconButton onClick={onAdd}>
                    <AddCircleOutlineIcon/>
                </IconButton>
            </Grid>
            <Grid item xs={'auto'}>
                <IconButton onClick={onDelete}>
                    <RemoveCircleOutlineIcon/>
                </IconButton>
            </Grid>
            <Grid item xs={'auto'}>
                <IconButton onClick={() => navigate(`/flash-card-sets/${flashCardSetId}/wizard`)}>
                    <PlayForWorkIcon/>
                </IconButton>
            </Grid>
            <Grid item xs={'auto'}>
                <IconButton disabled={summary.undefined === 0}
                            onClick={() => navigate(`/flash-card-sets/${flashCardSetId}/check`)}>
                    <ChecklistIcon/>
                </IconButton>
            </Grid>
            <Grid item xs={'auto'}>
                <IconButton disabled={summary.count === 0} onClick={() => onResetCards()}>
                    <RestoreIcon/>
                </IconButton>
            </Grid>
            <Grid item xs={'auto'}>
                <FormControl sx={{m: 1, minWidth: 120}} size="small">
                    <InputLabel id="demo-select-small-label">Filter</InputLabel>
                    <Select
                        id="filter-select"
                        labelId="demo-select-small-label"
                        value={filter}
                        label="Filter"
                        onChange={handleFilterChange}
                    >
                        <MenuItem value={""}><em>All</em></MenuItem>
                        <MenuItem value={"UNKNOWN"}>Unknown</MenuItem>
                        <MenuItem value={"LEARNED"}>Learned</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Stack direction={"row"} alignItems="center">
                <IconButton onClick={() => {
                    onMoveBackward(20);
                }}>
                    <FastRewindOutlinedIcon/>
                </IconButton>
                <IconButton onClick={onMoveBackward}>
                    <ArrowCircleUpIcon sx={{transform: 'rotate(-90deg)'}}/>
                </IconButton>
                <Box>
                    <Chip label={`${idx + 1} / ${dataLength}`} variant='outlined'/>
                </Box>
                <IconButton onClick={onMoveForward}>
                    <ArrowCircleUpIcon sx={{transform: 'rotate(90deg)'}}/>
                </IconButton>
                <IconButton onClick={() => {
                    onMoveForward(20);
                }}>
                    <FastForwardOutlinedIcon/>
                </IconButton>
            </Stack>
        </Grid>
    );
}

export default EditFlashCardsControls;
